import { SvgIcon } from '@mui/material';


const ServiceIcon = ({ service, color = '#EF9516', width = 24, height = 24}) => {
  const serviceCompact = service ? service.replace(/ |,|-|'/g, '').toLowerCase() : 'serviceandmaintenance';

  const icons = {
  airconheatingandcooling: (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 26.2"
          xmlSpace="preserve"
        >
          <path
            d="M11.9 12c-1-1.2-2.2-2.2-3-3.4-1.7-2.5-1-4.7 1.8-5.8 2.7-1.1 5.5-1 8.3.1.5.2 1 .6 1.5.9 1.5 1.3 1.3 2.8-.4 3.9-.7.4-1.5.8-2.2 1.3-1.2.9-1.2 1.6-.3 2.7.3.3.5.7.8 1 1.6-.3 3.2-.8 4.8-.8 2.8-.1 4.3 1.7 3.8 4.5-.6 3-1.9 5.5-4.5 7.2-.6.4-1.5.7-2.3.7-1.3.1-2-.7-2-2 0-1 .1-1.9-.1-2.9-.2-1.5-.8-1.9-2.2-1.7-.4.1-.8.1-1.4.1-.3 1-.5 2-.8 3-1.4 4.3-4.5 5-7.6 1.6-1.7-1.9-2.8-4.2-2.9-6.8 0-1.1.2-2.2 1.3-2.8 1-.6 1.9-.1 2.8.4.8.4 1.5.9 2.3 1.2 1.1.4 1.6.1 1.9-1.1 0-.4.2-.8.4-1.3zm3.8-1.6c.4-1.8 1.9-2.5 3.3-3.3.5-.3 1.2-.9 1.2-1.3-.1-.6-.6-1.3-1.2-1.6-2.8-1.4-5.7-1.5-8.6 0-1.4.7-1.8 2-.8 3.4.8 1.2 1.9 2.2 2.9 3.2.2.2.5.2.8.2.7-.2 1.5-.4 2.4-.6zm-4.4 5.1c-1.8.3-2.2.2-4.8-1.3-1.5-.9-2.5-.5-2.4 1.3.1 3.3 1.7 6 4.5 7.7 1.2.8 2.3.5 3.1-.8.5-.9.9-2 1.2-3 .4-1 .6-2-.6-2.8-.5-.1-.6-.7-1-1.1zm6.6 1.4c1.3 1.2 1.2 2.8 1.2 4.5 0 .6.2 1.6.5 1.7.6.2 1.5.1 2.1-.3 2.5-1.7 4-4.1 4.2-7.1.2-1.9-.9-3-2.8-2.8-1.3.1-2.7.6-4 .9-.2.1-.5.4-.6.7-.2.8-.4 1.5-.6 2.4zm-.3-2.7c0-1.4-1.3-2.6-2.7-2.6-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.3 2.7 2.7 2.7 1.4 0 2.6-1.3 2.6-2.7z"
            fill={color}
            stroke={color}
            strokeWidth="1.2411"
            strokeMiterlimit="10"
          />
      </svg>
    ),
  bodyworkanddentrepairs: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
        >
          <path
            d="M28 11.1c-.8-2.2-1.6-4.4-2.3-6.7-.4-1.2-1.2-1.7-2.4-1.7H13.1c-.7.1-1.4.1-2 .2-.9.1-1.7.2-2.6.3-.5 0-1.1.1-1.6.1-.1 0-.3.1-.4.1-.8.4-1.5.8-2.2 1.1-.5.3-.7.9-.5 1.4.2.5.5 1 .7 1.4 0 .1.1.1 0 .2l-2 2.2c-.4 1.2-.8 2.3-1.2 3.5 0 .1-.1.3-.1.4v12c0 1 .6 1.6 1.6 1.6h1.3c1.1 0 1.7-.6 1.7-1.7v-1.3h18.3V25.9c.1.7.5 1.2 1.2 1.3.6.1 1.3.1 2 0 .7 0 1.2-.5 1.4-1.2V13.3c-.2-.7-.5-1.4-.7-2.2zM6.8 7c-.2-.3-.4-.7-.6-1.1.3-.2.7-.3 1-.5h.1c.4 0 .9-.1 1.3-.1.8-.1 1.6-.2 2.4-.2.6-.1 2.4 0 3-.1h9.2c.2 0 .3 0 .3.2.7 2.1 1.4 4.3 2.1 6.4 0 .1 0 .1.1.2H4.3c0-.1 0-.2.1-.2l.3-.9 1.9-2.4C7 8 7 7.5 6.8 7zm-.2 12.6c-.7 0-1.3-.3-1.7-.8.6-.3 1.3-.7 1.7-1.2.4-.6.2-1.8.5-2.5 1 .2 1.8 1.2 1.8 2.2 0 1.2-1.1 2.3-2.3 2.3zm16.8 0c-1.2 0-2.3-1.1-2.3-2.3 0-1.2 1-2.3 2.3-2.3 1.2 0 2.3 1 2.3 2.3 0 1.3-1.1 2.3-2.3 2.3z"
            fill={color}
          />
      </svg>
    ),
  brakes: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 27.3"
          xmlSpace="preserve"
        >
          <path
            d="M14.4 25.4h-1.7c-.4-.1-.8-.1-1.1-.2-2.2-.5-4-1.5-5.6-3.2-1.3-1.5-2.2-3.2-2.6-5.1-.1-.4-.1-.8-.2-1.2v-1.5c0-.3.1-.6.1-.9.5-3 2.1-5.3 4.5-6.9 1.5-1 3.2-1.6 5.1-1.7h1.3v2.5c0 .2 0 .2.2.3.8.1 1.6.3 2.4.7 2.4 1.2 3.8 3.2 4.2 5.9 0 .2.1.2.3.2h2.5c.1.8.1 1.5-.1 2.2-.3 2.2-1.3 4.1-2.8 5.7-1.5 1.6-3.3 2.6-5.5 3-.3 0-.7.1-1 .2zM19.8 15c0-3.4-2.8-6.2-6.2-6.2-3.4 0-6.2 2.8-6.2 6.2 0 3.4 2.8 6.2 6.2 6.2 3.4 0 6.2-2.8 6.2-6.2z"
            fill={color}
          />
          <path
            d="M26.7 12.9h-4.4c-1-3.4-3.2-5.7-6.6-6.6V1.9c2.6.2 4.8 1.1 6.8 2.7 2.6 2.2 4 4.9 4.2 8.3zM17.4 12.1c.5.4.9 1.6.9 2.2H17v1.4h1.3c-.1.8-.4 1.5-.9 2.2l-.9-.9-1 1c.3.3.6.5.9.9-.7.5-1.4.8-2.2.9v-1.3h-1.4v1.3c-.8-.1-1.5-.4-2.2-.9l.9-.9-1-1-.9.9c-.5-.7-.8-1.4-.9-2.2H10v-1.4H8.7c.1-.8.4-1.5.9-2.2l.9.9 1-1-.9-.9c.7-.5 1.4-.8 2.2-.9v1.3h1.4v-1.3c.8.1 1.5.4 2.2.9l-.9.9 1 1 .9-.9zm-3.8.8c-1.1 0-2.1.9-2.1 2s.9 2.1 2.1 2.1c1.1 0 2.1-.9 2.1-2s-.9-2.1-2.1-2.1z"
            fill={color}
          />
          <path
            d="M13.6 14.3c.4 0 .7.3.7.7 0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7z"
            fill={color}
          />
      </svg>
    ),
  clutchandgearboxrepairs: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 25.3"
          xmlSpace="preserve"
        >
          <path
            d="M14 12.3V12 8.4c0-.2 0-.3-.2-.4-1.1-.4-1.8-1.7-1.7-2.7.1-1.5 1.1-2.5 2.3-2.7 1.4-.3 2.9.5 3.3 1.8.5 1.5-.1 2.9-1.5 3.6-.2.1-.3.2-.3.4v3.9H22v-.4-3.5c0-.2-.1-.4-.3-.5-1.1-.5-1.8-1.7-1.7-2.8.1-1.3 1-2.4 2.3-2.6 1.6-.3 3.1.6 3.5 2.3.3 1.4-.4 2.6-1.7 3.2-.1 0-.1 0-.2.1V17.9c0 .2.1.3.3.4 1.2.6 1.7 1.7 1.7 2.7-.1 1.8-1.3 2.5-2.3 2.7-1.4.3-2.9-.5-3.3-1.8-.5-1.5.1-2.9 1.5-3.6.1 0 .2-.2.2-.3v-3.8-.1h-6.1V17.9c0 .2.1.3.2.4 1.2.5 1.7 1.7 1.7 2.8-.1 1.4-.9 2.4-2.2 2.7-1.7.4-3.3-.8-3.5-2.5-.1-1.4.7-2.6 1.8-3 .1 0 .2-.2.2-.2v-3.8-.1h-8v-.3-5.5c-.1-.3-.2-.4-.4-.5C4.6 7.4 4 6.2 4.2 4.8c.1-1.1 1.2-2.1 2.3-2.3 1.5-.3 2.9.6 3.3 2.1.4 1.3-.3 2.8-1.7 3.4-.1 0-.2.2-.2.3v4H14z"
            fill={color}
          />
      </svg>
    ),
  diagnosticsandinspections: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
        >
          <path
            d="M26.5 21.5v-.2c0-.1 0-.3-.1-.4 0-.1 0-.1-.1-.2 0-.1-.1-.3-.1-.4 0-.1-.1-.1-.1-.2s-.1-.2-.1-.4c0-.1-.1-.2-.1-.2-.1-.1-.1-.2-.2-.3 0-.1-.1-.1-.1-.2-.1-.1-.1-.2-.2-.3 0-.1-.1-.1-.1-.2-.1-.1-.1-.2-.2-.3-.1-.2-.1-.2-.2-.3-.1-.1-.2-.2-.2-.3l-.2-.2c-.1-.1-.2-.2-.3-.2L24 17c-.1-.1-.2-.1-.3-.2-.1 0-.1-.1-.2-.1-.1-.1-.2-.1-.3-.2-.1 0-.2-.1-.2-.1-.1-.1-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.1h-1.2c-1.1 0-2.1.3-3 .8-.2.1-.4.2-.5.3-.5.3-.9.8-1.3 1.2-.1.2-.2.3-.4.5-.2.3-.4.7-.6 1.1-.2.4-.3.8-.4 1.2-.1.4-.1.8-.1 1.3v.8c0 .1 0 .3.1.4 0 .1 0 .1.1.2 0 .1.1.3.1.4 0 .1.1.1.1.2s.1.2.1.4c0 .1.1.1.1.2.1.1.1.2.2.3 0 .1.1.1.1.2.1.1.1.2.2.3 0 .1.1.1.1.2.1.1.1.2.2.3l.2.2c.1.1.2.2.2.3l.2.2c.1.1.2.2.3.2.1.1.1.1.2.1.1.1.2.1.3.2.1 0 .1.1.2.1.1.1.2.1.3.2.1 0 .2.1.2.1.1.1.2.1.3.1.1.1.2.1.2.1.1 0 .2.1.3.1.1 0 .2 0 .3.1.1 0 .2.1.3.1H20.2c.7 0 1.3-.1 1.9-.3.4-.1.8-.3 1.1-.5 1.3-.7 2.2-1.8 2.8-3.1.2-.4.3-.8.4-1.2.1-.4.1-.8.1-1.3v-.4zm-1.7-1.6c-.5.6-1 1.2-1.4 1.8-1 1.2-2 2.5-3 3.7l-.2.2h-.1l-.1.1h-.1s-.1 0-.1.1h-.1-.1c-.1 0-.2 0-.3-.1-.1-.1-.2-.1-.4-.3-.9-.8-1.8-1.5-2.6-2.3-.2.1-.3 0-.3-.1-.1-.2-.2-.4-.1-.6 0-.1 0-.2.1-.3.1-.1.1-.2.2-.3.1-.1.2-.1.3-.1h.5c.1 0 .2.1.3.2.7.6 1.4 1.2 2.2 1.9l2.4-3c.5-.6 1-1.2 1.5-1.9l.3-.3h.1c.1 0 .2-.1.2-.1H24.3c.1 0 .2.1.3.1.4.3.5.9.2 1.3z"
            fill={color}
          />
          <path
            d="M7.3 24.6h-.4c-.3-.1-.5-.4-.5-.7 0-.3.2-.6.6-.7H12.6c0-.3-.1-.7-.1-1 0-1 .2-2 .5-2.9H7.1c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5 0-.4.3-.7.7-.7h6.6c1.4-2.2 3.7-3.6 6.5-3.6 1.2 0 2.4.3 3.4.8v-11c0-1-.7-1.7-1.7-1.7h-5v1.1H19.7c.4 0 .6.2.6.6 0 .4-.2.5-.6.5H7.4c-.4.2-.6.1-.6-.3s.2-.6.7-.6h2.7V2.5H5.3c-1.1 0-1.8.7-1.8 1.8v21.5c0 1.2.7 1.8 1.8 1.8h9.5c-.8-.8-1.5-1.9-1.9-3H7.3zM7.1 7.4H20c.3 0 .4.1.5.2.1.1.2.3.2.5 0 .4-.3.7-.7.7h-.1H7c-.2 0-.4-.1-.5-.2-.1-.1-.1-.3-.1-.5 0-.4.3-.7.7-.7zm0 5.3h13c.3 0 .4.1.5.2.1.1.2.3.2.5 0 .4-.3.7-.7.7H20 7.1c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5 0-.5.3-.7.7-.7zM5 25.9c.1.1.3.2.5.2-.2 0-.4 0-.5-.2z"
            fill={color}
          />
          <path
            d="M11.2 4.1H15.9c.4 0 .6-.2.6-.5V2.1c0-.3-.2-.5-.5-.5h-4.9c-.3 0-.5.2-.5.6v1.4c0 .4.2.5.6.5z"
            fill={color}
          />
      </svg>
    ),
  electricalandlights: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 18.1"
          xmlSpace="preserve"
        >
          <path
            d="M2.5 8.4c.1-.4.2-.9.3-1.4 1-3.1 3.1-5 6.3-5.6.3-.1.7-.1 1-.1h6.4v15.6h-6.3c-3.9 0-7.2-2.9-7.7-6.7 0-.1 0-.2-.1-.4.1-.5.1-.9.1-1.4zM19.7 2.1l1.2.3c1.8.5 3.6.9 5.4 1.4.7.2 1.2.7 1.2 1.4s-.3 1.3-1 1.6c-.3.1-.6.1-1 .1-2.1-.5-4.1-1.1-6.2-1.6-.6-.2-1.1-.8-1.1-1.4 0-.7.4-1.3 1-1.6.1-.2.3-.2.5-.2zM19.7 11.4l1.2.3c1.8.5 3.6.9 5.4 1.4.7.2 1.2.8 1.2 1.5s-.4 1.4-1.1 1.5c-.3.1-.6.1-.8 0-2.1-.5-4.1-1-6.2-1.6-.7-.2-1.1-.7-1.2-1.4 0-.7.4-1.3 1-1.6.1 0 .3 0 .5-.1zM19.7 6.8c.3.1.7.2 1.1.3 1.8.5 3.6.9 5.4 1.4.7.2 1.2.8 1.2 1.5s-.4 1.3-1.1 1.5c-.3.1-.6.1-.8 0-2.1-.5-4.2-1-6.2-1.6-.7-.2-1.2-.8-1.1-1.6 0-.7.5-1.3 1.2-1.4 0-.1.2-.1.3-.1z"
            fill={color}
          />
      </svg>
    ),
  engine: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 22"
          xmlSpace="preserve"
        >
          <path
            d="M15.3 3.1V5H18c.5 0 .7.2.7.7v1.4h3c.6 0 .8.2.7.7v2h2.1V7.7h2.2c.2 0 .4.3.5.5.9 2 1.2 4 .9 6.1-.2 1.5-.6 3-1 4.4-.1.4-.3.6-.7.5h-2v-2.4h-2.1v2.6c0 1.4 0 1.4-1.3 1.4h-6.1c-.4 0-.7-.1-.9-.4-.6-.7-1.2-1.4-1.7-2.2-.3-.4-.6-.5-1.1-.5H5.7c-.6 0-.8-.1-.7-.7v-4H2.9v3.5c0 .3.1.7 0 1-.1.2-.4.6-.6.6-.3 0-.5-.4-.7-.6-.1-.1 0-.2 0-.3V7.5c0-.3.2-.7.4-.9.5-.1 1 .3 1 .9v4.2h2.1V8c0-.9 0-.9 1-.9h2.2V5h2.9V3.1H8.8c-.3 0-.7-.1-.9-.3-.2-.3-.4-.8-.2-1.1.2-.3.6-.6 1-.7C9.8.9 10.9 1 12 1h5.7c.8 0 1.3.4 1.3 1s-.5 1-1.3 1c-.8.1-1.6.1-2.4.1zm-2.7 0v1.5c0 .1.2.4.3.4.3.1.7 0 1 0V3.1h-1.3z"
            fill={color}
          />
      </svg>
    ),
  exhaustandemissions: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 23.2"
          xmlSpace="preserve"
        >
          <path
            d="M2 20.5v-.1c-.3-.6-.2-1.2.2-1.6.6-.7 1.1-1.2 1.8-1.8.9-.8 2.5-.8 3.4.1.4.4.8.7 1.1 1.1l.3.3 1.2-1.2c.1-.1 0-.2 0-.3-.2-.6-.4-1.2-.3-1.9.1-.6.3-1.1.7-1.5L20.5 3.5c.8-.8 2.1-1 3.1-.5l-.4.4-1.1 1.1c-.4.3-.5.7-.3 1.1.2.5.5 1.1.8 1.5.6.9 1.4 1.5 2.3 1.9.2.1.5.2.8.2.5 0 .7-.2.6-.6-.1-.4-.2-.7-.3-1-.1-.2-.1-.3.1-.5.4-.4.8-.7 1.1-1.1.9 1.1 1.3 2.9.6 4-.1.2-.3.5-.5.7-3.3 3.3-6.6 6.5-9.8 9.8-1 1-2 1.1-3.2.7-.9-.3-1.7-.8-2.5-1.5-.1 0-.1-.1-.2-.1l-.9.9c-1.2 1.2-2.8 1.2-4 0-.3-.5-.7-.8-1.1-1.2l-.8.8-.7.7c-.5.4-1 .4-1.5.2h-.1l-.5-.5z"
            fill={color}
          />
          <path
            d="M26.6 5.3l-1.4 1.4c-.2.2-.4.3-.7.1-.7-.3-1.1-.8-1.4-1.4-.1-.3-.1-.5.1-.7L25.9 2c.2-.2.5-.3.7-.1.6.3 1.1.8 1.4 1.4.1.3.1.5-.1.7l-1.3 1.3z"
            fill={color}
          />
      </svg>
    ),
  helpmeimnotsure: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.53 40.49">
          <defs />
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                d="M1 17.23v-2.51a2.57 2.57 0 012.31-2.61A2.51 2.51 0 016 14.36 44.85 44.85 0 016 20a2.46 2.46 0 01-2.66 2.24A2.41 2.41 0 011 19.73v-2.5z"
                fill={color}
              />
              <path
                d="M27.52 18.41a3.84 3.84 0 00-1-2.84 3 3 0 00-.44-.36H13c-1 0-2.18.06-2.57.51a1.73 1.73 0 00-.18 1.38 4.63 4.63 0 004.21 3.8h6.03a.65.65 0 01.69.65.66.66 0 01-.65.69 7.72 7.72 0 00-5.93 2.53c-2.46 2.95-1.81 7.56-1.8 7.61a.67.67 0 01-.57.76h-.1a.67.67 0 01-.66-.57c0-.21-.76-5.24 2.09-8.67a7.68 7.68 0 011.2-1.15 1.43 1.43 0 00-.09-.27.94.94 0 00-.12-.22H6.7a3.32 3.32 0 01-2.94 1.61 6.3 6.3 0 01-2.92-.7l-.1.06A1.93 1.93 0 000 24.74v1.92C.07 34 5.2 39.55 12.77 40.42a11.85 11.85 0 001.33.07 13.51 13.51 0 0013-10.77 43.19 43.19 0 00.44-8.26c-.03-1.02-.04-2.04-.02-3.05z"
                fill={color}
              />
              <path
                d="M19.75 13.86c0-1.74.36-3.93-1.39-4.76a3.11 3.11 0 00-2.6.16c-1.49 1-1.07 3-1 4.6zM26.48 13.86a1.52 1.52 0 000-.21V2.44A2.28 2.28 0 0024 .17a2.42 2.42 0 00-2.42 2.34c-.12 3.57-.05 7.76 0 11.35zM8.35 20.76a.47.47 0 00.28.14H11a5.94 5.94 0 01-2.11-3.61 3 3 0 01.51-2.46 1.73 1.73 0 01.28-.26.71.71 0 01.14-.1l.18-.12.2-.09.16-.07a2 2 0 01.26-.09h.14l.32-.07h.12l.39-.05H12.75V13a2.32 2.32 0 00-2.24-2.57 2.58 2.58 0 00-2.68 2.76c-.06 1.61-.08 3.22 0 4.83a16.18 16.18 0 00.52 2.74z"
                fill={color}
              />
            </g>
          </g>
      </svg>
    ),
  interioraudio: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
        >
          <path
            d="M24.1 12.4c.1.2.3.4.4.6.7 1 1.1 2 1.2 3.2v.6c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-.5-.1-1-.2-1.5-.2-.8-.5-1.4-1-2.1-.9-1.1-2-1.8-3.4-2.1-.4-.1-.7-.1-1.1-.1-.1 0-.2 0-.3-.1-.2-.1-.3-.3-.2-.5.1-.2.2-.3.5-.3 1.2 0 2.3.3 3.3.9.3.2.7.5 1 .7 0 0 .1 0 .1.1.2.1.5.3.7.6z"
            fill={color}
          />
          <path
            d="M22.2 13.2c.7.7 1.2 1.4 1.4 2.3.1.4.2.9.2 1.3 0 .3-.1.4-.4.5-.2.1-.4 0-.5-.2 0-.1-.1-.2-.1-.3 0-.7-.2-1.4-.6-2-.7-1-1.7-1.6-2.9-1.7H19c-.3 0-.5-.2-.5-.5s.2-.5.5-.5c.9 0 1.7.2 2.5.7.3.1.5.3.7.4zM6.6 7.9c1.3-.5 2.3-2 2.3-3.5 0-.2 0-.4-.1-.6-.1-.8-.4-1.5-.9-2.1-.9-.8-1.9-.5-2.2.7-.4 1.3-.6 2.6-.9 3.9-.1.4-.1.7.1 1 .3.7 1 .8 1.7.6zM19.7 16.1l4.3 4.3c.1.1.2.3.2.5 0 .3-.2.4-.5.4-.7.1-1.5.2-2.2.2-1.2.1-2.4.3-3.6.4-.1 0-.1 0-.2.1l-1.4 1.4c-.3.3-.6.4-1.1.3-.1-.1-.3-.2-.4-.3l-2.6-2.6c-.4-.4-.4-1.1 0-1.5.5-.5 1-.9 1.4-1.4.1-.1.1-.2.1-.2l.6-5.7c0-.4.4-.6.7-.5.1 0 .2.1.2.2 1.6 1.5 3 3 4.5 4.4z"
            fill={color}
          />
          <path
            d="M12.3 20.9l1.2 1.2c-.2-1-.4-1.9-.6-2.9 0-.1-.1-.2-.1-.3l-.5.5c-.5.4-.4 1.1 0 1.5zM15.3 23.8c.1 0 .2.1.3.1.3 0 .5-.1.7-.3l.7-.7c-.9.1-1.7.2-2.6.3l.4.4c.2 0 .3.1.5.2z"
            fill={color}
          />
          <path
            d="M24.3 22.9c-.1 0-.2.1-.4.1l-1.4.2-.8.1-.6.1c-.8.1-1.7.2-2.5.3l-.9.9-.2.2c-.7.7-1.4.8-1.9.8-.3 0-.6-.1-.9-.2-.5-.2-.8-.4-1-.7l-2.1-2.1-.5-.5c-1.1-1.1-1.1-2.8 0-3.9l.6-.6.5-.5v-.4c-.5-1.7-1.3-3.3-2.3-4.9-.6-.9-1.3-1.8-2.2-2.5-.6-.5-1.3-.8-2.1-.7-.8 0-1.2.4-1.3 1v1.2c.2 1.5.6 3.1 1 4.5.5 1.7 1 3.3 1.6 4.9.7 2.1 1.6 4.1 2.3 6.2.1.4.1.6.2.9.3.8.6 1.3 1.3 1.3h13c.5 0 .9-.2 1.2-.4.2-.2.4-.6.5-1 .1-.7.2-1.4 0-2.2 0-.8-.4-1.6-1.1-2.1z"
            fill={color}
          />
      </svg>
    ),
  mobilemechanicsandservices: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
        >
          <path
            d="M24.5 8.9c-.7-3.9-3.7-7-7.5-7.8-.3-.1-.8-.1-1.2-.2h-1.7c-.3 0-.5.1-.8.1-4.7.8-7.8 4.8-8.1 9.1-.1 1.9.3 3.7 1.2 5.3 2.3 4.2 4.6 8.5 6.9 12.7.3.6.8 1 1.5 1s1.2-.3 1.6-1c2.3-4.2 4.6-8.5 6.9-12.7 1.3-2 1.6-4.2 1.2-6.5z"
            fill={color}
          />
          <path
            d="M6.6 15.6c1.5-1.5 2.9-2.9 4.4-4.3.2-.2.3-.4.2-.7-.3-1.1-.1-2.2.6-3.1.4-.6 1-1.1 1.8-1.4.4-.1.7-.2 1.1-.3h.6c.2 0 .5.1.8.1l-.5.5-1.7 1.7c-.1.1-.1.1-.1.2.2.7.4 1.3.5 2l.1.1c.7.2 1.3.3 2 .5h.2c.6-.5 1.1-1.1 1.7-1.7l.5-.5v.2c.2.1.2.3.3.5v.5c0 .2-.1.3-.1.5-.3 1.3-1 2.2-2.2 2.8-.6.3-1.3.4-2 .4-.2 0-.4-.1-.6-.1-.1 0-.1 0-.2.1l-2.1 2.1-2.6 2.6c-.3.2-.6.5-.8.7"
            fill="#fff"
          />
      </svg>
    ),
  securitylockingandkeys: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 18.4"
          xmlSpace="preserve"
        >
          <path
            d="M9.5 11.9H2.7c-.7 0-1.2-.5-1.2-1.2V7.4c0-.5.4-1 .9-1H3v-2C3 2.5 4.3 1.1 6.1 1 8 1 9.3 2.3 9.4 4.3v2.1h.4c.6 0 1.1.5 1.1 1.1v3c0 .8-.6 1.4-1.4 1.4zM7.7 6.4V3.9c-.1-.8-.6-1.2-1.4-1.3-.6-.1-1.5.5-1.5 1.1-.1.9 0 1.8 0 2.7h2.9z"
            fill={color}
          />
          <path
            d="M8.9 4.4c.1-.2.3-.4.4-.5 0-.4-.1-.7-.3-1-.4.2-.8.6-1 1.2-.1.1-.2.3-.3.5v1.8h-.8c-.2.3-.3.6-.5.9 0 .2-.1.2-.3.2-1.1 0-2 .7-2.3 1.7 0 .1-.1.3-.1.4v2.2h5.8c.8 0 1.4-.6 1.4-1.4v-3H7.4c0-.1.1-.2.1-.2.5-.9.9-1.8 1.4-2.8z"
            fill={color}
          />
          <path
            d="M28.4 10.6c0-1.2-.9-2.2-2-2.4-1.1-.2-2.2-.4-3.4-.6-.2 0-.3-.1-.4-.3-.8-1.2-1.7-2.5-2.5-3.7-.4-.5-.9-.9-1.5-1-.2 0-.4-.1-.6-.1H10.1c.1.3.2.6.3 1H13.7v3.9H12v3c0 1.3-1.1 2.4-2.4 2.4H3.7v.9c.2.8.7 1.2 1.5 1.2h.9c.4 1.5 1.3 2.4 2.9 2.5 1.6 0 2.6-.9 3-2.4h8c.1.2.1.4.2.6.5 1.2 1.7 1.9 2.9 1.8 1.4-.1 2.4-1 2.7-2.3 0-.1.1-.2.2-.2h.8c.8 0 1.5-.7 1.5-1.5.1-.9.1-1.8.1-2.8zM7.1 14.5s0-.1 0 0c0-.3.1-.6.2-.8-.1.2-.1.5-.2.8zm12.8-7h-5.3V3.6H18c.5 0 .9.2 1.2.6.7 1.1 1.5 2.1 2.2 3.2 0 0 0 .1.1.1h-1.6z"
            fill={color}
          />
      </svg>
    ),
  serviceandmaintenance: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
        >
          <path
            d="M28.8 10.7c0-.2-.1-.5-.1-.7v-.2l-.6.6-1.9 1.9s-.1.1-.2 0c-.7-.2-1.5-.4-2.2-.6-.1 0-.1-.1-.1-.1-.2-.7-.4-1.5-.6-2.2 0-.1 0-.2.1-.3L25 7.3l.6-.6c-.3-.1-.6-.1-.9-.1h-.6-.1c-.4 0-.9.1-1.3.3-.8.3-1.5.9-2 1.6v.1c-.3.5-.6 1.1-.7 1.6v.1c0 .3-.1.5-.1.8 0 .3 0 .6.1.9v.2c0 .2-.1.4-.2.5-1.7 1.6-3.3 3.3-5 4.9l-.5.5c-1.5 1.5-3.1 3-4.6 4.6-.1.1-.2.3-.3.4l-.1.1c-.1.1-.1.2-.1.3v.2c0 .2-.1.3-.1.5s0 .4.1.6c.3.9 1.1 1.5 2 1.4.7 0 1.2-.3 1.6-.7.5-.6 1.1-1.1 1.7-1.7l1.2-1.2 1-1 1.2-1.2 2.9-2.9c.4-.4.7-.7 1.1-1l1.3-1.3s.1-.1.2-.1c.2 0 .5.1.7.1.8.1 1.6-.1 2.3-.4 1.3-.7 2.2-1.7 2.4-3.1 0-.2.1-.4.1-.6-.1 0-.1-.2-.1-.4z"
            fill={color}
          />
          <path
            d="M11.3 19.1c-2.7 0-4.9-2.3-4.9-5.1 0-3.4 3-5 5.2-5 2.7 0 4.9 2.3 4.9 5.1v.1c.7-.7 1.4-1.4 2.2-2.1-.2-1.1-.1-2.1.3-3.1h-.5c-1.2-.1-2.4-1.5-1.8-3 .1-.2.2-.5.3-.7.1-.2 0-.4-.2-.5-.8-.4-1.7-.7-2.5-1-.2-.1-.4 0-.5.2l-.3.6c-.4.8-1 1.3-2 1.3s-1.8-.6-2.1-1.3c0-.2-.1-.4-.2-.6-.1-.3-.3-.4-.5-.3-.9.4-1.7.7-2.5 1.1-.2.1-.3.3-.2.5l.3.6c.2.4.2.8.1 1.2-.2 1.1-1.3 2.1-2.7 1.8-.3-.1-.6-.2-1-.4-.2-.1-.4 0-.5.2-.3.8-.7 1.7-1 2.5-.1.2 0 .4.2.5l.6.3c.8.4 1.3 1.1 1.3 2.1 0 .9-.5 1.7-1.3 2l-.6.3c-.2.1-.3.3-.2.5.3.9.7 1.7 1.1 2.6.1.2.3.3.5.2.2-.1.4-.1.5-.2.3-.1.6-.2.9-.2 1.2 0 2.6 1.2 2.2 2.8l-.3.9c-.1.2 0 .4.2.5.5.2 1.1.5 1.6.7.1-.8.4-1.5 1-2.2.9-.9 1.8-1.8 2.8-2.7-.2-.2-.3-.2-.4-.2z"
            fill={color}
          />
      </svg>
    ),
  suspensionandsteering: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
        >
          <path
            d="M25.9 22.8c-.3-.2-.6-.4-1-.6.1-.5-.1-1-.5-1.4l-3.5-3.5-.3-.3c0 .1 0 .1-.1.1L17 20.6s-.1 0-.1.1l.1.1c.1.1.1.2.2.3l3.5 3.5c.4.4.9.5 1.4.4.2.3.4.5.5.8.5 1 1.4 1.5 2.5 1.4 1-.1 1.9-1 2.1-1.9.1-1.1-.4-2.1-1.3-2.5zm-1.1 3.3c-.6 0-1.2-.5-1.2-1.2 0-.6.5-1.1 1.2-1.1.6 0 1.1.5 1.1 1.1.1.7-.4 1.2-1.1 1.2zM9.1 13.5l4.4-4.4c.3-.3.1-.9-.4-1.4-.5-.5-1.1-.6-1.4-.4l-4.4 4.4c-.3.3-.1.9.4 1.4.5.6 1.1.7 1.4.4zM6.3 10.7l4.4-4.4c.1-.1.3-.2.4-.3L9.2 4.1c-.5-.5-1.1-.5-1.6 0-.2.3-.4.4-.6.6-.3-1.5-1.2-2.4-2.4-2.4-1.1 0-2.2.9-2.3 2-.2 1.4.5 2.3 2.3 2.9l-.6.6c-.4.4-.5.9-.1 1.3.7.7 1.5 1.4 2.1 2.1.1-.2.2-.3.3-.5zM4.6 5.8c-.7 0-1.1-.5-1.1-1.2 0-.6.5-1.1 1.1-1.1.6 0 1.2.5 1.2 1.1 0 .7-.5 1.2-1.2 1.2zM16.8 17.7l-4.4-4.4c-.3-.3-.1-.9.4-1.4.5-.5 1.1-.6 1.4-.4l4.4 4.4c.3.3.1.9-.4 1.4-.5.6-1.1.7-1.4.4zM16.2 19c-1.7-1.7-3.3-3.3-4.9-5-.1-.1-.2-.1-.3 0l-.7.7c-.5.4-.8.9-.2 1.4l-5 5c-.4.4-.6 1-.4 1.6-.3.2-.6.4-1 .6-1 .5-1.6 1.7-1.2 2.8.3 1 1.2 1.7 2.3 1.7 1 0 1.8-.7 2.3-1.6.1-.3.4-.5.6-.8.5.1 1-.1 1.4-.5L14 20h.1c.1 0 .1.1.2.1s.1.1.2.1c.3.1.4.1.6-.1.6-.4.6-.5 1.1-.9.1-.1.1-.2 0-.2zM4.8 26.5c-.7 0-1.1-.5-1.1-1.2 0-.6.5-1.1 1.1-1.1.7 0 1.2.5 1.2 1.1 0 .7-.5 1.2-1.2 1.2zM27.8 4.6c0-1.1-.8-2-1.9-2.2-.7-.2-1.4 0-1.9.5s-.7 1.1-1.1 1.7l-.2-.2c-.8-.8-1.2-.8-2 0l-1.5 1.5c.2.1.3.2.5.3l4.4 4.4c.1.1.3.3.3.5.4-.4.8-.8 1.2-1.3 1.1-1.1 1.1-1.3.1-2.5 0 0-.1-.1-.1-.2 1.5-.4 2.2-1.1 2.2-2.5zm-2.4 1.3c-.6 0-1.2-.5-1.1-1.2 0-.6.5-1.1 1.2-1.1s1.1.5 1.1 1.2c-.1.6-.6 1.1-1.2 1.1zM19 15.5l-4.4-4.4c-.3-.3-.1-.9.4-1.4.5-.5 1.1-.6 1.4-.4l4.4 4.4c.3.3.1.9-.4 1.4-.5.5-1.1.7-1.4.4zM21.4 13.4c.3.1.8 0 1.2-.4.5-.5.6-1.1.4-1.4l-4.4-4.4c-.3-.3-.9-.1-1.4.4-.4.4-.6.9-.4 1.2l4.6 4.6z"
            fill={color}
          />
      </svg>
    ),
  tyreswheelsandalignment: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 30"
          xmlSpace="preserve"
        >
          <path
            d="M13.3 22.9v-3.4c0-.2-.2-.5-.5-.7-3-2-3-5.5 0-7.6.2-.1.4-.4.4-.7V7.2h-2.9c.3-1.1.5-2 .8-2.9 0-.1.3-.2.5-.3h7.7c-.3 1.1-.6 2.1-.8 3.2h-2v3.4c0 .2.3.5.5.6 2.8 1.9 2.8 5.5 0 7.4-.2.2-.5.5-.5.7-.1 1.1 0 2.2 0 3.3h2.3v3.2h-8v-3.1c.9.2 1.7.2 2.5.2zm3.5-7.7c0-1-.8-1.8-1.8-1.9-1 0-1.9.7-1.9 1.7s.8 1.9 1.8 1.9c1.1.1 1.9-.7 1.9-1.7zM5.7 24.5v-3.4c0-.6.2-.9.9-.9H9c.6 0 .9.2.9.9v6.8c0 .6-.2.9-.9.9H6.6c-.7 0-.9-.3-.9-1 .1-1.1 0-2.2 0-3.3zM24.3 24.5v3.3c0 .7-.2 1-.9 1h-2.5c-.5 0-.8-.2-.8-.8v-7c0-.3.4-.5.7-.8h.2c3.2-.2 3.2-.2 3.2 3 .1.5.1.9.1 1.3zM4.8 9c.5-1.9.9-3.5 1.4-5.2.7-2.7.7-2.7 3.4-2 1.2.3 1.3.4.9 1.6L9 9.4c-.2.9-.6 1.1-1.4.8-.7-.3-1.4-.4-2.1-.6-.3-.1-.5-.4-.7-.6z"
            fill={color}
          />
          <path
            d="M25.2 2.5c-.5 1.9-.9 3.6-1.4 5.3-.9 3.1-.1 2.7-3.4 1.9-1.3-.3-1.3-.4-.9-1.6.5-2 1.1-4 1.6-6 .2-.8.5-1 1.3-.7.6.2 1.4.3 2.1.6.3.1.5.4.7.5z"
            fill={color}
          />
      </svg>
    ),
  windowswindscreensandmirrors: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 30 18.4"
          xmlSpace="preserve"
        >
          <path
            d="M17.1 15.2c-.1-.6-.7-1-1.4-1h-.2c-.2-.2-1.5-1.7-1.7-1.9-1.4-1.6-2.7-3.1-4.1-4.7-.1-.2-.3-.3-.6-.2-.4.1-.6.6-.4.9.1.1.1.2.2.2 1.2 1.4 2.5 2.9 3.7 4.3l.2.2c.3.3 1.4 1.6 1.6 1.9.1.1.1.2.1.4-.1.5.1 1 .5 1.3.4.3.9.4 1.4.2.6-.3.9-.9.7-1.6z"
            fill={color}
          />
          <path
            d="M15.8 14.2H15.5c-.2-.2-1.5-1.7-1.7-1.9-1.4-1.6-2.7-3.1-4.1-4.7 0-.2-.2-.3-.4-.3h-.2c-.4.2-.6.7-.4 1 .1.1.1.2.2.2 1.2 1.4 2.5 2.9 3.7 4.3l.2.2c.3.3 1.4 1.6 1.6 1.9.1.1.1.2.1.4h.5c.4 0 .7 0 1.1.1.4.1.7.2 1.1.3v-.5c-.2-.6-.7-1-1.4-1z"
            fill={color}
          />
          <path
            d="M29.5 7.3c-3-3-6.5-5-10.7-5.8-.8-.1-2.4-.4-3.7-.4H15h-.1c-1.3 0-3 .3-3.7.4C7.1 2.3 3.5 4.3.5 7.3c-.2.2-.2.6 0 .9l8.9 8.9c.4.4.8.4 1.2 0 .1-.1.3-.2.4-.3.7-.6 1.4-1 2.2-1.2v-.1c-.3-.3-.7-.8-1.1-1.3l-.3-.4-.1-.1-.1-.1-1-1.2-2.7-3c0-.1-.1-.2-.2-.4-.4-.5-.4-1.1-.2-1.7.2-.6.6-1 1.2-1.2.2-.1.4-.1.6-.1.4 0 1 .1 1.5.7l1.9 2.1c.7.8 1.5 1.7 2.2 2.5l.2.3c.2.3.7.9 1.1 1.3 1.1.1 2 .9 2.2 2 .1.5.1.9 0 1.3.2.1.4.3.6.5.1.1.3.2.4.3.4.3.9.3 1.2 0l8.9-8.9c.2-.2.2-.6 0-.8z"
            fill={color}
          />
      </svg>
    ),
  };
  return <SvgIcon style={{ width: width, height: height }}>
    {icons[serviceCompact] ? icons[serviceCompact] : <></>}
  </SvgIcon>
};

export default ServiceIcon;
